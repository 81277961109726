































import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import drawInsCom from './asComponents/drawIns.vue'
import { CommonMudule } from '@/store/modules/common'
import { MyCompony, OptArr, AslistItem, SeriesType } from '@/types/kyc'
import { getAssetsInfo, getAssetsList } from '@/api/asKYC'
import { getcomplete } from '@/api/common'

@Component({
  name: 'pension',
  components: {
    moduleHeader,
    drawInsCom
  }
})

export default class extends Vue {
  private assetsList:Array<any> = []
  private isFirst: Boolean = true
  hadAsset = []
  created() {
    if (this.isFirst) {
      this.isFirst = false
      this.preFetch()
    }
  }

  async preFetch() {
    const res = await getAssetsInfo({
      assetTypeId: 'total-insurance-0001',
      customerId: CommonMudule.customerId
    })
    let bFlag = false
    if (res.success && res.statusCode === 0) {
      if (res.data && res.data.customerMemberAssetVOList && res.data.customerMemberAssetVOList.length) {
        res.data.customerMemberAssetVOList.forEach((item: any) => {
          if (item.memberType === 'Self' || item.memberType === 'Mate') {
            if (item.customerAssetSubList && item.customerAssetSubList.length) {
              item.customerAssetSubList.forEach((opt: any) => {
                if (opt.assetTypeId === 'sub-total-insurance-0004') {
                  if (opt.customerAssetList && opt.customerAssetList.length) {
                    bFlag = true
                  }
                }
              })
            }
          }
        })
      }
    }
    this.initFetch(bFlag)
  }

  activated() {
    if (!this.isFirst) {
      this.initFetch()
    }
  }
  preSubmit() {
    this.$router.push({
      path: '/pension/index',
      query: {
        type: 'edit'
      }
    })
  }
  finishSubmit() {
    this.$router.push('/pension/plan')
  }
  refresh() {
    this.preFetch()
  }
  initFetch(bFlag?: boolean) {
    this.isFirst = false
    this.assetsList = [
      {
        assetInputType: 'MemberTab',
        assetPerType: null,
        assetTypeName: '保险',
        calFieldParams: null,
        calFieldValue: null,
        createBy: null,
        createByName: null,
        createTime: null,
        deleted: false,
        id: 'total-insurance-0001',
        level: 1,
        parentId: '-1',
        seq: 12,
        systemAssetType: '',
        tenantId: null,
        visible: bFlag || false
      }
    ]
  }
}
